import { ClientEnum, AppCategoryEnum } from '@/constants/enumLogin'
import { isNextServer } from "@/utils/uaParser";
import loginConfig from '@/constants/loginConfig'
import { serialize, parse } from "cookie";

const cookieUtils = {
  // 获取指定名称的 cookie
  getCookie(name: any) {
    // 服务端环境
    if (isNextServer()) {
      try {
        // 动态导入服务端模块（避免客户端打包）
        const { headers } = require('next/headers');
        const cookiesItem = parse(headers().get('cookie') || '');
        return cookiesItem[name] || "";
      } catch (e) {
        console.error('Server-side cookie parse error:', e);
        return "";
      }
    }
    if (typeof document === "undefined") {
      return "";
    }
    const cookieName = name + "="
    const cookieArray = document.cookie.split(';') // 拆分成数组

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim()
      if (cookie.indexOf(cookieName) === 0) {
        try {
          return decodeURIComponent(cookie.substring(cookieName.length, cookie.length)) // 安全解码
        } catch (e) {
          console.error('Error decoding cookie:', e)
          return "" // 解码失败时返回 null
        }
      }
    }
    return "" // 如果未找到指定的 cookie
  },

  // 设置 cookie，带可选的过期时间（以天为单位）
  setCookie(
    name: string,
    value: string,
    expireDays: number = 30,
    domain?: string,
    path?: string,
  ): any {
    if (isNextServer()) {
      const { headers } = require('next/headers');
      const { NextResponse } = require('next/server');
      const mainDomain = this.getMainDomainFromHost(headers().get("host") || "");
      const exp = new Date();
      exp.setDate(exp.getDate() + expireDays);
      const cookieStr = serialize(name, value, {
        expires: exp,
        domain: domain || mainDomain,
        path: path || '/',
        secure: process.env.NODE_ENV === 'production',
        httpOnly: true,
        sameSite: 'lax',
      });
      const response = NextResponse.next();
      response.headers.append('Set-Cookie', cookieStr);
      return response;
    }
    const exp = new Date();
    exp.setDate(exp.getDate() + expireDays);
    const domainSet =
      ";domain=" +
      (domain ? domain : window.location.hostname.substring(window.location.hostname.indexOf(".")));
    const pathSet = path ? ";path=" + path : ";path=/";
    const secureFlag = window.location.protocol === 'https:' ? ';secure' : '';
    document.cookie = name + "=" + encodeURIComponent(value) + ";expires=" + exp.toUTCString() + domainSet + pathSet + secureFlag;

  },

  // 删除指定名称的 cookie
  deleteCookie(name: any) {
    this.setCookie(name, "", -1) // 通过将过期时间设为过去来删除 cookie
  },

  setUserCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 计算过期时间
      expires = "; expires=" + date.toUTCString(); // 设置过期时间
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + ";path=/" + ";secure"; // 设置 cookie
  },

  getMainDomainFromHost(host: string) {
    // 去除端口号部分
    const domainWithoutPort = host.split(":")[0];
    // 提取主域名部分
    const mainDomain = domainWithoutPort.split(".").slice(-2).join(".");
    return mainDomain;
  },

  getCookieDomainByHostname(isLevelOne = false) {
    let host = location.hostname;
    let ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (ip.test(host) === true || host === 'localhost') {
      return host;
    }
    let regex = /([^]*).*/;
    let match = host.match(regex);
    if (typeof match !== "undefined" && null !== match) {
      host = match[1];
    }
    if (typeof host !== "undefined" && null !== host) {
      let strAry = host.split(".");
      if (strAry.length > 1) {
        if (isLevelOne) {
          host = strAry[strAry.length - 2] + "." + strAry[strAry.length - 1];
        } else {
          host = strAry.slice(1, strAry.length).join(".");
        }
      }
    }
    return '.' + host;
  },

  setLocalStorageInAPI() {
    const pathname = location.pathname || "";
    if (
      pathname.indexOf(`/${ClientEnum.B}`) > -1 ||
      pathname.indexOf(`/${ClientEnum.BO}`) > -1
    ) {
      this.setLocalStorage(loginConfig.LoginCookieStorageType.APP_CATEGORY_KEY, AppCategoryEnum.B);
    } else if (pathname.indexOf(`/${ClientEnum.C}`) > -1) {
      this.setLocalStorage(loginConfig.LoginCookieStorageType.APP_CATEGORY_KEY, AppCategoryEnum.C);
    } else if (
      pathname.indexOf(`/${ClientEnum.E}`) > -1 ||
      pathname.indexOf(`/${ClientEnum.EJ}`) > -1
    ) {
      this.setLocalStorage(loginConfig.LoginCookieStorageType.APP_CATEGORY_KEY, AppCategoryEnum.E);
    } else {
      this.setLocalStorage(loginConfig.LoginCookieStorageType.APP_CATEGORY_KEY, AppCategoryEnum.B);
    }
  },
  setLocalStorage(key: any, value: any) {
    let valueString = typeof value === "string"
      ? value
      : JSON.stringify(value);
    window.localStorage.setItem(key, valueString);
  },
  getLocalStorage(key: any) {
    return window.localStorage.getItem(key) || "";
  },
  clearLocalStorage(key: any) {
    window.localStorage.removeItem(key);
  },
  setSessionStorage(key: any, value: any) {
    let valueString = typeof value === "string"
      ? value
      : JSON.stringify(value);
    window.sessionStorage.setItem(key, valueString);
  },
  getSessionStorage(key: any) {
    return window.sessionStorage.getItem(key) || "";
  },
  clearSessionStorage(key: any) {
    window.sessionStorage.removeItem(key);
  }
}

export const parseCookieString = (cookieStr: string): { [key: string]: string } => {
  const cookies: { [key: string]: string } = {};
  const pairs = cookieStr.split(';');
  pairs.forEach(pair => {
    const [key, value] = pair.split('=').map(item => item.trim());
    cookies[key] = value;
  });
  return cookies;
};

export default cookieUtils
